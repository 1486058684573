.main-container-bg-color {
    background-image: linear-gradient(#000, #000, #000, #000, #000);
}

.body {
    margin: 0;
    padding: 0;
    /* height: 925vh; */
    max-width: 100vw;
    width: 100%;
    background: #212529;
    /* font-family: Book Antiqua,Palatino,Palatino Linotype,Palatino LT STD,Georgia,serif; */
    /* font-family: Cambria, Georgia, serif; */
    font-family: 'Light-Font';
    /* --anita-t-content-ff: 'Open Sans', sans-serif; */
}

.section-01 {
    background: #212529;
    /* background: #000; */
    height: 100vh;
    max-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60px;
    transform: translateY(50px);
    opacity: 0;
    visibility: hidden;
    /* background-image: url('../images/1.jpg'); */
    font-family: 'Light-Font';
}

.section-05 {
    background: #000;
    /* background: #000; */
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60px;
    opacity: 0;
    visibility: hidden;
}

.section-06 {
    background: #000;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60px;
    opacity: 0;
    visibility: hidden;
}

@media (max-width: 991px) {
    .mainContainer {
        position: fixed;
        right: 0;
        bottom: 0;
        min-width: 100vw;
        min-height: 100vh;
        max-height: 100vh;
        /* width: 80%; */
    }
}

@media (min-width: 991.1px) {
    .mainContainer {
        position: fixed;
        right: 0;
        bottom: 0;
        min-width: 100vw;
        min-height: 100vh;
        max-height: 100vh;
        /* width: 80%; */
    }
}

@media (min-width: 1300px) {
    .mainContainer {
        position: fixed;
        right: 0;
        bottom: 0;
        min-width: 100vw;
        min-height: 120vh;
        /* max-height: 100vh; */
        /* width: 80%; */
    }
}

.why-margin-left-right {
    padding-left: 8vw;
    padding-right: 8vw;
}

.main-content {
    position: fixed;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #f1f1f1;
    font-family: 'Light-Font';
    width: 100%;
    height: 100%;
    padding: 0px 20px 20px 20px;
}

@media (min-width: 1200px) {
    .why-margin-left-right {
        padding-left: 8vw;
        padding-right: 8vw;
    }
}

@media (max-width: 991px) {
    .why-margin-left-right {
        padding-left: 6vw;
        padding-right: 6vw;
    }
}

@media (max-width: 767px) {
    .why-margin-left-right {
        padding-left: 2vw;
        padding-right: 2vw;
    }

    .main-content {
        position: fixed;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        color: #f1f1f1;
        font-family: 'Light-Font';
        width: 100%;
        height: 100%;
        padding: 0px 15px 15px 15px;
    }
}



.section-01 span,
/* .section-02-1 span {
    background: linear-gradient(90deg, #fc466b 40%, #00dbde 62.5%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Bold-Font';
} */

/* .section-02-2 span {
   
} */
.main-header-nyonai-container {

    /* -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Bold-Font';
}

.main-header-nyonai {
    background: linear-gradient(90deg, #fc466b 40%, #00dbde 62.5%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section-02-subContainer {
    width: 90%;
}

.section-02-1 {
    background: #000;
    /* background: green; */
    height: 150vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 60px;
    transform: translateY(50px);
    opacity: 0;
    visibility: hidden;
}

.section-02-2 {
    background: #000;
    /* background: #222429; */
    height: 200vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 60px;
    transform: translateY(50px);
    opacity: 0;
    visibility: hidden;

}

.section-02-2-layer-2 {
    height: 200vh;
    width: 100vw;
    background-color: #000;
    background-image: linear-gradient(#000, #3399FF, #000);
    z-index: 99999;
}

.fixed-position-02-2 {
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    /* aligns on vertical for column */
    align-items: center;
    padding-top: 12.5vh;
    /* z-index: 1; */
}

.section-07 {
    background: #000;
    height: 120vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60px;
    opacity: 0;
    visibility: hidden;
}

.section-08 {
    background: #000;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60px;
    opacity: 0;
    visibility: hidden;
}

.footer {
    background: #000;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
}

.section-04 {
    background: #000;
    height: 300vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60px;
    opacity: 0;
    visibility: hidden;
}

.section-04-02 {
    background: #000;
    height: 140vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60px;
    opacity: 0;
    visibility: hidden;
}

.black-bg {
    background: #000 !important;
}

@media (max-width: 767.9px) {
    .body {
        margin: 0;
        padding: 0;
        /* height: 925vh; */
        /* max-width: 100vw;
        width: 100%; */
        background: #000;
        /* font-family: Book Antiqua,Palatino,Palatino Linotype,Palatino LT STD,Georgia,serif; */
        /* font-family: Cambria, Georgia, serif; */
        font-family: 'Light-Font';
        /* --anita-t-content-ff: 'Open Sans', sans-serif; */
    }

    .main-container-bg-color {
        background: #000;
    }

    .section-02-1 {
        background: #000;
        height: 400vh;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        font-size: 60px;
        transform: translateY(50px);
        opacity: 0;
        visibility: hidden;
    }

    .section-02-2 {
        background: #000;
        /* background: #222429; */
        height: 420vh;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        font-size: 60px;
        transform: translateY(50px);
        opacity: 0;
        visibility: hidden;

    }

    .section-02-2-layer-2 {
        height: 380vh;
        width: 100vw;
        background-color: #000;
        background-image: linear-gradient(#000 62.5%, #a342d1, #000 62.5%);
        z-index: 99999;
        align-items: center;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
    }

    .section-06 {
        background: #000;
        height: 190vh;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 60px;
        opacity: 0;
        visibility: hidden;
    }

    .section-07 {
        background: #000;
        height: 145vh;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 60px;
        opacity: 0;
        visibility: hidden;
    }

    .section-08 {
        background: #000;
        height: 235vh;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 60px;
        opacity: 0;
        visibility: hidden;
    }

    .section-04-02 {
        background: #000;
        height: 400vh;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 60px;
        opacity: 0;
        visibility: hidden;
    }
}

.service-img {
    width: 100%;
    height: 250px;
}

.service-header1 {
    color: #6230f6;
    font-size: 30px;
    text-align: center;
}

.service-header2 {
    color: #ce2dbf;
    font-size: 28px;
    text-align: center;
}

.service-header3 {
    color: #00c6c3;
    font-size: 30px;
    text-align: center;
}

.service-header4 {
    color: #ffb62f;
    font-size: 30px;
    text-align: center;
}

.service-content {
    font-size: 22px;
    text-align: center;
    color: #fff;
    margin-top: 30px;
    opacity: 0;
}

.section-03 {
    background: #000;
    height: 300vh;
    display: flex;
    justify-content: center;
    font-size: 60px;
    opacity: 0;
    visibility: hidden;
    padding-top: 100px;
}




.section-03-1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    justify-content: flex-end;
    /* position: fixed; */
    top: 0px;
    opacity: 1;
    /* visibility: hidden; */
    background-color: #000;
}

.postion-fixed {
    position: fixed;
}


.section-03-1-1 {
    flex: 1;
    display: flex;
    align-items: left;
    padding-left: 2vw;
    /* justify-content: center; */
}

.flex-style {
    display: flex;
    flex-direction: row;
    justify-content: center;
}


@media (min-width: 1670px) {
    .video-subtitle-box {
        width: 80%;
        height: 80vh;
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-size: 35px;
        line-height: 100px;
        padding-left: 50px;
        color: #fff;
    }

    .video-box {
        width: 80%;
        height: 500px;
        /* background: orange; */
    }

    .margin-bottom-video {
        margin-top: 33vh;
    }

    .fixed-position-02-2 {
        position: fixed;
        top: 0;
        left: 0;
        align-items: center;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        /* aligns on vertical for column */
        align-items: center;
        padding-top: 15vh;
        /* z-index: 1; */
    }

    .section-02-1 {
        background: #000;
        /* background: green; */
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        font-size: 60px;
        transform: translateY(50px);
        opacity: 0;
        visibility: hidden;
    }
}

@media (max-width: 1669px) {
    .video-subtitle-box {
        width: 70%;
        height: 48vh;
        font-size: 42px;
        line-height: 60px;
        /* padding-left: 50px; */
        color: #fff;
        text-align: left;
    }

    .video-box {
        width: 90%;
        height: 300px;
        /* background: orange; */
    }

    .margin-bottom-video {
        margin-top: 30vh;
    }

    .fixed-position-02-2 {
        position: fixed;
        top: 0;
        left: 0;
        align-items: center;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        /* aligns on vertical for column */
        align-items: center;
        padding-top: 15vh;
        /* z-index: 1; */
    }


}

@media (max-width: 1200px) {
    .video-subtitle-box {
        width: 80%;
        height: 50vh;
        font-size: 32px;
        line-height: 50px;
        /* padding-left: 50px; */
        color: #fff;
        text-align: left;
    }

    .video-box {
        width: 90%;
        height: 300px;
        /* background: orange; */
    }

    .margin-bottom-video {
        margin-top: 28.5vh;
    }

    .fixed-position-02-2 {
        position: fixed;
        top: 0;
        left: 0;
        align-items: center;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        /* aligns on vertical for column */
        align-items: center;
        padding-top: 11vh;
        /* z-index: 1; */
    }
}

@media (max-width: 998px) {
    .video-subtitle-box {
        width: 90%;
        height: 40vh;
        font-size: 32px;
        line-height: 60px;
        /* padding-left: 50px; */
        color: #fff;
        text-align: center;
    }

    .video-box {
        width: 90%;
        height: 300px;
        /* background: orange; */
    }

    .margin-bottom-video {
        margin-top: 33vh;
    }
}

@media (max-width: 767.9px) {
    .video-subtitle-box {
        width: 90%;
        height: 50vh;
        font-size: 32px;
        line-height: 60px;
        /* padding-left: 50px; */
        color: #fff;
        text-align: center;
        margin-top: 32vh;
    }

    .video-box {
        width: 90%;
        height: 300px;
        margin-top: 5vh;
        /* background: orange; */
    }

    .margin-bottom-video {
        margin-top: 33vh;
    }
}

.top-margin-md {
    margin-top: 20vh;
}

@media (max-width: 485px) {
    .video-subtitle-box {
        width: 100%;
        height: 40vh;
        font-size: 28px;
        line-height: 40px;
        /* padding-left: 50px; */
        color: #fff;
        text-align: center;
    }

    .video-box {
        width: 90%;
        height: 40px;
        /* background: orange; */
    }
}

.active-text {
    background: linear-gradient(90deg, #3c8cc7 0, #a342d1 62.5%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section-03-1-2 {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.video-image {
    width: 100%;
    border-radius: 15px;
}

.service-box {
    width: 20%;
}

.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
}

.hidden {
    /* opacity: 0; */
    visibility: hidden;
    max-height: 0vh;
}

.why-title {
    text-align: center;
    color: #fff !important;
    font-size: 24px;
    font-family: 'Bold-Font';
    cursor: pointer;
    text-transform: uppercase;
}

.why-title:hover {
    text-align: center;
    color: rgb(51, 153, 255) !important;
    font-size: 24px;
    font-family: 'Bold-Font';
    cursor: pointer;
}

.why-title-sm {
    text-align: center;
    color: #fff;
    font-size: 24px;
    font-family: 'Light-Font';
    padding: 5px;
    padding-right: 5px;
}

.why-background-card-size {
    width: 100%;
    height: 150px;
    border-radius: 10px;
}

@media (min-width: 1200px) {
    .why-background-card-size {
        width: 100%;
        height: 200px;
    }
}

@media (max-width: 991px) {
    .why-background-card-size {
        width: 100%;
        height: 150px;
    }
}

@media (max-width: 767px) {
    .why-background-card-size {
        width: 100%;
        height: 250px;
    }
}

.why-background-card-1 {
    background-image: url('../images/1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.why-background-card-2 {
    background-image: url('../images/2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.why-background-card-3 {
    background-image: url('../images/3.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.why-background-card-4 {
    background-image: url('../images/4.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.why-background-card-5 {
    background-image: url('../images/5.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.why-background-card-6 {
    background-image: url('../images/1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.why-background-card-7 {
    background-image: url('../images/2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.why-background-card-8 {
    background-image: url('../images/3.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

@media (min-width: 1200px) {
    .why-background-card {
        height: 160px;
        background-size: cover;
    }
}

@media (max-width: 991px) {
    .why-background-card {
        height: 110px;
        background-size: cover;
    }

    .icont-text-1 {
        font-size: 35px;
        text-align: center;
    }
}

@media (max-width: 767px) {
    .why-background-card {
        height: 250px;
        background-size: cover;
    }

    .icont-text-1 {
        font-size: 45px;
        text-align: center;
    }
}

.icont-text-1 {
    font-size: 45px;
    text-align: center;
}

.mt-icon-new {
    padding-top: 18%;
}

@media (min-width: 1200px) {
    .mt-icon-new {
        padding-top: 18%;
    }
}

.why-title-1 {
    font-size: 20px !important;
    text-align: center !important;
    font-family: 'Light-Font';
    padding-top: 0px;
    margin-bottom: 0rem !important;
}

.why-title-2 {
    color: #FFB62F;
    letter-spacing: 9px;
    font-size: 19px;
    font-weight: 400;
    text-align: center;
    font-family: 'Light-Font';
    font-weight: bold;
}

.why-title-color-1 {
    color: rgb(255, 0, 51);
}

.why-title-color-2 {
    color: rgb(51, 153, 255);
}

.why-title-color-3 {
    color: rgb(255, 0, 255);
}

.why-title-color-4 {
    color: rgb(102, 102, 255);
}

.why-title-color-5 {
    color: rgb(0, 204, 255);
}

.why-title-color-6 {
    color: rgb(51, 153, 255);
}

.why-title-color-7 {
    color: rgb(102, 102, 255);
}

.why-title-color-8 {
    color: rgb(255, 0, 51);
}

.why-description-1 {
    font-size: 16px !important;
    text-align: center !important;
    /* color: white !important; */
}

.text-color-main-1:hover {
    color: white;
}

.text-color-main-2:hover {
    color: white;
}

.text-color-main-3:hover {
    color: white;
}

.text-color-main-4:hover {
    color: white;
}

.text-color-main-5:hover {
    color: white;
}

.text-color-main-6:hover {
    color: white;
}

.text-color-main-7:hover {
    color: white;
}

.text-color-main-8:hover {
    color: white;
}

.why-container-1 {
    color: rgba(255, 0, 51, 0);
    cursor: pointer;
}

.why-container-2 {
    color: rgba(51, 153, 255, 0);
    cursor: pointer;
}

.why-container-3 {
    color: rgba(255, 0, 255, 0);
    cursor: pointer;
}

.why-container-4 {
    color: rgba(102, 102, 255, 0);
    cursor: pointer;
}

.why-container-5 {
    color: rgba(0, 204, 255, 0);
    cursor: pointer;
}

.why-container-6 {
    color: rgba(51, 153, 255, 0);
    cursor: pointer;
}

.why-container-7 {
    color: rgba(102, 102, 255, 0);
    cursor: pointer;
}

.why-container-8 {
    color: rgba(255, 0, 51, 0);
    cursor: pointer;
}

.why-container-1:hover {
    color: rgba(255, 0, 51, 1);
}

.why-container-1:hover span {
    color: rgba(255, 0, 51, 1);
}

.why-container-2:hover {
    color: rgba(51, 153, 255, 1);
}

.why-container-2:hover span {
    color: rgba(51, 153, 255, 1);
}

.why-container-3:hover {
    color: rgba(255, 0, 255, 1);
}

.why-container-3:hover span {
    color: rgba(255, 0, 255, 1);
}

.why-container-4:hover {
    color: rgba(102, 102, 255, 1);
}

.why-container-4:hover span {
    color: rgba(102, 102, 255, 1);
}

.why-container-5:hover {
    color: rgba(0, 204, 255, 1);
}

.why-container-5:hover span {
    color: rgba(0, 204, 255, 1);
}

.why-container-6:hover {
    color: rgba(51, 153, 255, 1);
}

.why-container-6:hover span {
    color: rgba(51, 153, 255, 1);
}

.why-container-7:hover {
    color: rgba(102, 102, 255, 1);
}

.why-container-7:hover span {
    color: rgba(102, 102, 255, 1);
}

.why-container-8:hover {
    color: rgba(255, 0, 51, 1);
}

.why-container-8:hover span {
    color: rgba(255, 0, 51, 1);
}

#gradient {
    height: 300px;
    width: 300px;
    border: 1px solid black;
    font-size: 30px;
    background: linear-gradient(130deg, #ff7e00, #ffffff, #5cff00);
    background-size: 200% 200%;

    -webkit-animation: Animation 5s ease infinite;
    -moz-animation: Animation 5s ease infinite;
    animation: Animation 5s ease infinite;
}

@-webkit-keyframes Animation {
    0% {
        background-position: 10% 0%
    }

    50% {
        background-position: 91% 100%
    }

    100% {
        background-position: 10% 0%
    }
}

@-moz-keyframes Animation {
    0% {
        background-position: 10% 0%
    }

    50% {
        background-position: 91% 100%
    }

    100% {
        background-position: 10% 0%
    }
}

@keyframes Animation {
    0% {
        background-position: 10% 0%
    }

    50% {
        background-position: 91% 100%
    }

    100% {
        background-position: 10% 0%
    }
}

.logo-icon {
    max-width: 120px;
    height: auto;
}

.transparent-bg-color {
    background-color: rgba(102, 102, 255, 0);
    ;
}

.custom-height {
    display: flex;
    flex-direction: column;
    /* margin-bottom: 10vh; */
}

.margin-bottom-header {
    margin-top: 15vh;
}



.nav-icon-close {
    font-size: 30px;
    padding-top: 10px;
    color: #f1f1f1;
    margin-right: 30px;
}

.nav-icon-close:hover {
    font-size: 30px;
    padding-top: 10px;
    color: #fc466b;
    margin-right: 30px;
}

.nav-icon-open {
    font-size: 30px;
    padding-top: 10px;
    color: #f1f1f1;
    margin-right: 30px;
}

.nav-icon-open:hover {
    font-size: 30px;
    padding-top: 10px;
    color: #00dbde;
    margin-right: 30px;
}

.main-modal-container {
    background-color: #000;
    height: 100vh;
    width: 100vw;
}

.postion-fixed-header {
    height: 10vh;
    width: 100vw;
    position: fixed;
    z-index: 99999;
    padding: 40px 40px 40px 40px;
}

.main-content-nav {
    position: fixed;
    bottom: 0;
    background: rgba(0, 0, 0, 0.1);
    color: #f1f1f1;
    font-family: 'Light-Font';
    width: 100%;
    height: 100%;
}

.nav-container-height {
    height: 100vh;
    width: 100vw;
}



.col-style {
    align-items: center;
    vertical-align: middle !important;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.col-style:hover {
    background-color: rgba(255, 255, 255, 0);
    /* background-color: rgba(0, 0, 0, 0); */
}

.col-style:hover .nav-text-new {
    /* color: #9f20d6 !important; */
    background: linear-gradient(90deg, #fc466b 40%, #00dbde 62.5%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* font-family: 'Bold-Font'; */
    font-weight: bold;
}

.menu-right-border {
    border-right: 1px solid #6e6e6e;
}

.nav-text-new {
    text-align: center !important;
    color: #fff !important;
    text-decoration: none !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    font-size: 35px;
}

.nav-text-new:hover {
    text-align: center !important;
    text-decoration: none !important;
}

@media (min-width: 767px) {
    .nav-text-new {
        text-align: center !important;
        color: #fff !important;
        margin-top: 22vh;
    }
}

@media (max-width: 767px) {
    .min-height-col {
        max-height: 150px;
    }

    .menu-bottom-border {
        border-bottom: 1px solid #6e6e6e;
    }

    .menu-top-border {
        border-top: 1px solid #6e6e6e;
    }

    .nav-container-height {
        height: 70vh;
        padding-top: 25vh;
    }

    .nav-text-new {
        text-align: center !important;
        color: #fff !important;
        text-decoration: none !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
        font-size: 18px;
    }

    .logo-icon {
        max-width: 90px;
        height: auto;
    }

    .nav-icon-close {
        font-size: 24px;
        padding-top: 5px;
        color: #f1f1f1;
        margin-right: 5px;
    }

    .nav-icon-open {
        font-size: 24px;
        padding-top: 5px;
        color: #f1f1f1;
        margin-right: 5px;
    }

    .nav-icon-close:hover {
        font-size: 24px;
        padding-top: 5px;
        color: #fc466b;
        margin-right: 5px;
    }

    .nav-icon-open:hover {
        font-size: 24px;
        padding-top: 5px;
        color: #00dbde;
        margin-right: 5px;
    }
}

.mouse-hover-nav {
    cursor: -webkit-grab;
    cursor: grab;
}

.animated-text {
    animation: colorchange 0.5s ease-in-out;
}

@keyframes colorchange {
    from {
        color: #000;
    }

    to {
        color: #4d4d4d;
    }
}

.client-font {
    font-family: 'Client-Font' !important;
    color: white;
    font-size: 18px;
}

.box-width {
    min-width: 13vw;
    max-width: 13vw;
    height: 16vh;
}

.zoomInEffect {
    transition: transform 1s;
}

.zoomInEffect:hover {
    transform: scale(1.2);
    /* font-size: 38px; */
    background: -webkit-linear-gradient(#eee 0%, #fc466b 90%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.border-right {
    border-right: 1px solid white;
}

/* .border-bottom {
    border-bottom: 5px solid black;
} */

.border-top-1 {
    border-top: 1px solid white;
}

.mt-client-logo {
    margin-top: 3vh;
}

.mouse-pointer {
    cursor: pointer;
}

.contact-us-content {
    color: white;
    font-size: 20px;
}

.icon-size-contact {
    height: 40px;
    width: 40px;
}

.form-container-1 {
    display: flex;
    flex-direction: row;
}

.input-field {
    background-color: #000 !important;
    width: 100%;
    color: white !important;
    border: 3px solid #212529 !important;
}

.submit-btn {
    background-color: #000 !important;
    color: white !important;
    border: 3px solid #212529 !important;
}

.submit-btn:hover {
    background-color: #212529 !important;
    color: white !important;
    border: 1px solid white !important;
}

.border-1 {
    border-style: groove;
}

.card-aboutUs {
    max-height: 15vh !important;
    background-color: #212529 !important;
}

.card-title {
    font-size: 28px;
    text-align: center;
    font-family: 'Bold-Font';
    color: white;
}

.card-descrition {
    font-size: 18px;
    text-align: center;
    font-family: 'Bold-Font';
    color: white;
}

.image-aboutUS {
    /* border: 2px solid #fff; */
    width: 100%;
    height: 35vh;
    overflow: hidden;
}

.image-aboutUS img {
    width: 100%;
    height: 30vh;
    transition: all 1s ease-in-out;
}

.image-aboutUS:hover img {
    transform: scale(1.1, 1.1);
    cursor: pointer;
}

.aboutUs-title-2 {
    color: #212529;
    font-size: 28px;
    font-family: 'Bold-Font';
}

.link-name {
    color: white;
    text-decoration: none;
}

.link-name:hover {
    color: white;
    text-decoration: none;
}

.mt-50 {
    margin-top: 50px;
}

.mb-100 {
    margin-bottom: 100px;
}

.mb-200 {
    margin-bottom: 200px;
}

.font-icon-1 {
    color: white;
    font-size: 22px;
    text-align: center;
    margin-top: 12px;
}

.icon-size {
    width: 65px;
    height: 65px;
}

.row-gap-about {
    padding-top: 40px;
    padding-bottom: 40px;
}

.footer-font {
    color: white;
    font-size: 24px;
    text-align: center;
}

@media (min-width: 767px) {}

@media (max-width: 767px) {
    .box-width {
        min-width: 30vw;
        max-width: 30vw;
        height: 16vh;
    }

    .zoomInEffect {
        transition: transform 1s;
    }

    .zoomInEffect:hover {
        transform: scale(1.1);
        font-size: 18px;
        background: -webkit-linear-gradient(#eee 0%, #fc466b 90%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .form-container-1 {
        display: flex;
        flex-direction: column;
    }

    .mt-50 {
        margin-top: 10px;
    }

    .mb-100 {
        margin-bottom: 25px;
    }

    .mb-200 {
        margin-bottom: 50px;
    }

    .row-gap-about {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .client-font {
        font-family: 'Client-Font' !important;
        color: white;
        font-size: 15px;
    }

    .zoomInEffect {
        transition: transform 1s;
    }

    .zoomInEffect:hover {
        transform: scale(1.1);
        background: -webkit-linear-gradient(#eee 0%, #fc466b 90%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
.footer-line {
    height: 1px;
    background-color: #eee;
}